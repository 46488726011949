import React, { useEffect } from "react";
import { useState } from "react";

import styles from "./resources_page.module.css";

const faq_data = [
  {
    question: "For ReplyAssist Customers",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          <a href="https://docs.google.com/document/d/1nojq4oMfrclUoop63FhjcO1T2juX_OVXHKhG04AmJNk/edit" target="_blank">
            ReplyAssist New Customer Guide
          </a>
        </strong>
        <br></br>
        <br></br>
        <strong>
          <a href="https://www.loom.com/share/706da5dd91ed4177afd37d105d1e5a8d?sid=ad3b8b5a-64a8-4400-9bdf-c8374711aa1f" target="_blank">
            How to Set up a Campaign in ReplyAssist
          </a>
        </strong>
        <br></br>
        <br></br>
        <strong>
          <a href="https://docs.google.com/document/d/14unSvqwd8jICoZtO4Td2oWe5BN7qwTafRcKZHt3zT3M/edit" target="_blank">
          Criteria for a Successful ReplyAssist New Connections Campaign
          </a>
        </strong>
        <br></br>
        <br></br>
        <strong>
          <a href="https://www.loom.com/share/21f48800fcbe4692a7e4eaeee278b423?sid=7cae2564-32b4-48dc-9c99-9524e1e058fe" target="_blank">
            Using ReplyAssist Blacklist Feature
          </a>
        </strong>
        <br></br>
        <br></br>
        <strong>
          <a href="https://www.loom.com/share/516b3a459e754c888f23ef653bf2272d?sid=24d47fd8-4c40-4d48-a8c1-bfb70b701391" target="_blank">
            ReplyAssist Automated Follow Ups Explained
          </a>
        </strong>
        <br></br>
        <br></br>
        <strong>
          <a href="https://docs.google.com/document/d/13MWNDJXPujFFwJCMtbSXAIVO1b1eByxeOSC_RBN92zg/edit" target="_blank">
            Handling LinkedIn Activity
          </a>
        </strong>
      </p>
    ),
  },
  {
    question: "Prospecting & Sales Resources",
    answer: (
      <p className={styles.answer_text}>
        <p>
        <strong>For Prospecting:</strong>
        <br></br>
        <br></br>
        <strong>
          • <a href="https://www.linkedin.com/posts/jonbregman_things-i-dont-care-about-as-a-buyer-activity-7209528334991478784-K5Mg?utm_source=share&utm_medium=member_desktop" target="_blank">
          Prospecting is about your prospect, NOT you - so, this will help you craft better Connection Request Messages on LinkedIn
          </a>
        </strong>
        <br></br> 
        <strong>
          • <a href="https://www.linkedin.com/posts/austin-mcculloh_if-you-do-any-prospecting-on-linkedin-i-activity-7211012693036605442-WOX-?utm_source=share&utm_medium=member_desktop" target="_blank">
          How to get a prospect to WANT to schedule a meeting with you
          </a>
        </strong>
        <br></br>
        <strong>
          • <a href="https://www.linkedin.com/posts/austin-mcculloh_exciting-update-the-attached-video-is-activity-7206679185912651776-UKfd?utm_source=share&utm_medium=member_desktop" target="_blank">
          Real Example of Consultative Selling: Sent Connection Request -{">"} Meeting Scheduled (which resulted in a new client)
          </a>
        </strong>
        <br></br>
        <strong>
          • <a href="https://www.linkedin.com/posts/brendanfrazier_weve-all-been-ghosted-by-a-prospect-activity-7209258490513473536-c_3V/?utm_source=share&utm_medium=member_desktop" target="_blank">
          How to message a prospect who is ghosting you
          </a>
        </strong>
        <br></br>
        <strong>
          • <a href="https://www.linkedin.com/posts/chrisbits_im-not-interested-call-me-in-6-months-activity-7211699746254544896-jnUi/?utm_source=share&utm_medium=member_desktop" target="_blank">
          The "peeling the onion" approach to handling objections from prospects
          </a>
        </strong>
        <br></br>
        <strong>
         • <a href="https://www.loom.com/share/fd6137ccb6004515b2709356552c57af?sid=a5bd5e76-6184-498e-88c6-5d8f73d42cca" target="_blank">
          Nudging Prospects Forward With Automated Follow Ups
          </a>
        </strong>
        <br></br> 
        <br></br>
        <br></br> 
        <strong>For Sales Calls:</strong>
        <br></br>
        <br></br>
        The typical sales process is...
        <br></br>
        <br></br>
        1. Intro/Discovery Call --{">"} 2. Demo Call --{">"} 3. Closing Call. But, the Intro/Discovery + Demo Calls can be combined into one single call <strong>if</strong> you have enough time.</p>
        <strong>
          • <a href="https://docs.google.com/document/d/1X5NPA_OKLFNkp3j9xvZ9-Hn9ANaEaOYYE9APCIgiP9c/edit?usp=sharing" target="_blank">
          Introduction/Discovery Call Guide
          </a>
        </strong>
        <br></br>
        <strong>
          • <a href="https://docs.google.com/document/d/1qC1BGZaT0USRwmJemM1UyHGge4IB29GCFhmbLZnFNfA/edit?usp=sharing" target="_blank">
          Demo Call Guide
          </a>
        </strong>
      </p>
    ),
  },
  {
    question: "Marketing Resources",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          <a href="https://www.loom.com/share/c187495825e84e4ca807e1e86fdfbe1c?sid=99d5d32f-6ebe-408e-978c-4b327408ea6f" target="_blank">
            Creating a Quality LinkedIn Profile
          </a>
        </strong>
        <br></br>
        <br></br>
        <strong>
          <a href="https://www.loom.com/share/118187cb227942338c919306686f8f72?sid=b89779aa-062d-483f-a00e-e8e9c8ff167c" target="_blank">
          How to Set Up an Effective Scheduling Link
          </a>
        </strong>
      </p>
    ),
  },
  {
    question: "Customer Results (best viewed on desktop)",
    answer: (
      <div>
      <p className={styles.answer_text}>
        <br></br>
      </p>
      <div className={styles.left_aligned_results_image_header}>
        <strong>#1 (Stan) Three Meetings in First Six Days</strong>
      </div>
      <img
        className={styles.three_meetings_in_six_days_image}
        src="/images/threemeetingsinsixdays.png"
        alt="resources"
      />
       <br></br>
       <br></br>
       <br></br>
       <br></br>
       <br></br>
       <div className={styles.left_aligned_results_image_header}>
        <strong>#2 (Ryan) Three Meetings in First Seven Days</strong>
      </div>
      <img
        className={styles.three_meetings_in_six_days_image}
        src="/images/ryan3meetings7days.png"
        alt="resources"
      />
       <br></br>
       <br></br>
       <br></br>
       <br></br>
       <br></br>
       <div className={styles.left_aligned_results_image_header}>
        <strong>#3 (Ryan) 1st Client in 26 Days Using ReplyAssist</strong>
      </div>
      <img
        className={styles.three_meetings_in_six_days_image}
        src="/images/ryan1stclient.png"
        alt="resources"
      />
       <br></br>
       <br></br>
       <br></br>
       <br></br>
       <br></br>
       <div className={styles.left_aligned_results_image_header}>
        <strong>#4 (JD) ReplyAssist Delivering Expected Results</strong>
      </div>
      <img
        className={styles.three_meetings_in_six_days_image}
        src="/images/jdreplyassistvalue.png"
        alt="resources"
      />
      <br></br>
       <br></br>
       <br></br>
       <br></br>
       <br></br>
       <div className={styles.left_aligned_results_image_header}>
        <strong>#5 (Financial Advisor in Minnesota) 6 Interested Candidates in First 4 Days</strong>
      </div>
      <img
        className={styles.three_meetings_in_six_days_image}
        src="/images/6interestedcandidatesinfirst4days.png"
        alt="resources"
      />
      </div>
    ),
  },
];



const ResourcesComponents = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.faq_components}>
      <div className={styles.faq_content}>
        {/* <p className={styles.title}>FAQ</p>
        <p className={styles.subtitle}>
          Everything you wanted to know about ReplyAssist! If you still have
          questions after reading the below FAQs, please reach out and let us
          know.
        </p> */}

        <div className={styles.page_header}>
          <div className={styles.page_titles}>
            <p className={styles.page_title}>Resources</p>
            <p className={styles.page_subtitle}>
           Here are some helpful resources to help guide you along!
            </p>
          </div>
        </div>
        <div className={styles.page_line_break}></div>

        <div className={styles.faq_list_items}>
          {faq_data.map((item, index) => (
            <div key={index}>
              <div
                className={styles.faq_tile}
                onClick={() =>
                  setExpandedIndex(index === expandedIndex ? null : index)
                }
              >
                <p className={styles.item_question}>{item.question}</p>
                <img
                  className={`${styles.down_arrow_image} ${
                    expandedIndex === index ? styles.flipped : ""
                  }`}
                  src="/images/down-arrow.png"
                  alt="expand"
                ></img>
              </div>
              {expandedIndex === index && (
                <div className={styles.faqanswer}>{item.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.spacer}></div>
    </div>
  );
};

export default ResourcesComponents;
